<!--  -->
<template>
  <div>

    <div v-if="list !== undefined && list.length > 0" style="margin-top: 55px">


      <div class="product">
        <div class="pro_item" v-for="item in list" :key="item.ID">
          <van-card
              :num="item.Count"
              :price="item.Price.toFixed(2)"
              :desc="item.Desc"
              :title="item.Name"
              :thumb="item.Picture"
          />
        </div>
        <div
            style="
            height: 10px;
            width: 100%;
            background-color: #f0f0f0;
            margin-bottom: 10px;
          "
        ></div>


        <div class="pro_express">
          应付合计
          <div style="float: right; color: red">
            ￥{{ totalPrice.toFixed(2) }}
          </div>
        </div>
        <!-- 底部操作栏 -->
        <div class="bottom">
          <!-- 总价 -->
          <van-button
              type="primary"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              block
              @click="pay"
          >支付</van-button
          >
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { getItem } from "@/utils/storage";
export default {
  data() {
    return {


      list: [{
        Count:'1',
        Name:'第一学年',
        Picture:'',
        Price:198,
        Desc:'第一学年卡,包含2个学期'

      }],

      totalPrice: 198



    };
  },

  components: {

  },

  computed: {},

  created() {


  },

  methods: {


    pay() {

      this.$axios
          .post("/api/Orders/AddOrders", {

            TotalPrice: this.totalPrice,
            UserID: getItem("UserID")

          })
          .then((res) => {
            console.log(res.data);
            let msg = res.data.Message;
            if (msg == "SUCCESS") {
              let key = res.data.Data;

              window.location.href =
                  //"http://tailor.smhhgc.cn/wxpay/JsApiPayPage.aspx?key=" + key;
                  "https://www.bjshiyi.cn/api/wxpay/WxFrepayment?key=" + encodeURIComponent(key);
            }
          })
          .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.address {
  margin: 10px 10px 0 10px;
  font-size: 14px;
  display: flex;
  height: 40px;
}
.pre {
  width: 10%;
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
}
.info {
  display: flex;
  width: 80%;
  line-height: 20px;
  color: red;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
}
.arrow {
  width: 10%;
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
}

.div_normal {
  color: #4a4a4a;
  font-weight: normal;
  padding-top: 10px;
  float: left;
  width: 7%;
}
.pro_express {
  height: 30px;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}
.bottom {
  position: fixed;
  width: 100%;
  background-color: #444;
  bottom: 0px;
  color: #fff;
}
.bottom-button {
  width: 160px;
  height: 40px;
}
</style>
